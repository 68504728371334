import React, { useContext } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';

import { 
  Container, 
  Typography, 
  Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
  Paper
 } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { ListItem } from '../components/CustomStyles/Typography';

import FormModalContext from '../context/FormModalContext';

// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		lineHeight: 1.8,
		fontFamily: 'Roboto',
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	subHeader: {
		[theme.breakpoints.down('sm')]: {
			lineHeight: '1.25',
			marginTop: '2.5rem',
		},
	},
	effectiveDate: {
		fontWeight: 600,
		fontSize: '2.25rem',
		letterSpacing: '-.5px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.25rem',
			whiteSpace: 'nowrap',
		},
	},
  listStyle: {
    fontSize: '1.125rem',
		color: '#4B5B69',
		lineHeight: 1.8,
		fontFamily: 'Roboto',
  },
	head: {
		background: theme.lightGray,
		color: '#4B5B69',
		fontSize: '1.125rem',
    fontWeight: '600',
    fontFamily: 'Roboto',
	},
	body: {
		fontSize: '1.125rem',
	},
}));

const PrivacyPolicyPage = ({ data, location }) => {
	const privacy = data.privacy.edges[0].node;
	const classes = useStyles();

	const { formModalOpen } = useContext(FormModalContext);

	const { 
    metaDescription, 
    metaTitle,
    hero, 
    logos, 
    _rawBeginningContent, 
    _rawCollectionTable,   
    _rawMiddleContent,
    _rawDataRetentionTable,
    _rawEndingContent
  } = privacy;
  //console.log(_rawMiddleContent);
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />

			<Hero hero={hero} />
			<Container style={{ marginBottom: '14rem' }}>
				<Grid
					container
					spacing={4}
					style={{ paddingBottom: '2rem' }}
					direction='row'
					justifyContent='center'
					alignItems='center'>
					<div>
						<a
							href='//privacy.truste.com/privacy-seal/validation?rid=8c98ce2d-da81-4d38-9591-
f1ecfae72b7b'
							target='_blank'>
							<img
								style={{ border: 'none' }}
								src='//privacy-policy.truste.com/privacy-
seal/seal?rid=8c98ce2d-da81-4d38-9591-f1ecfae72b7b'
								alt='TRUSTe'
							/>
						</a>
					</div>
					{logos.map((logo, index) => {
						return (
							<Grid
								item
								key={index}
								component='a'
								href={logo.logoLink}
								rel='nofollow, noreferrer'
								target='_blank'>
								<GatsbyImage image={logo.logo?.asset?.gatsbyImageData} />
							</Grid>
						);
					})}
				</Grid>
				<Grid container>
					<PortableText
						content={_rawBeginningContent}
						serializers={{
							// h2: ({ children }) => (
							// 	<Typography
							// 		color='primary'
							// 		variant='h2'
							// 		className={classes.subHeader}>
							// 		{children}
							// 	</Typography>
							// ),
							normal: ({ children }) => (
								<Grid item style={{ margin: '1rem 0' }}>
									<Typography variant='body1' className={classes.text}>
										{children}
									</Typography>
								</Grid>
							),
              strong: ({ children }) => (
                <span style={{ fontWeight: 600 }}>{children}</span>
              ),
							// h4: ({ children }) => (
							// 	<Typography
							// 		color='primary'
							// 		variant='h4'
							// 		className={classes.effectiveDate}>
							// 		{children}
							// 	</Typography>
							// ),
							emailPhoneLink: (props) => {
								return <a href={props.emailPhoneHref}>{props.children[0]}</a>;
							},
              link: (props) => {
                return (
                  <a
                    href={props.href}
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: '#4B5B69' }}>
                    {props.children[0]}
                  </a>
                );
              },
              internalLink: (props) => {
                return (
                  <Link
                    to={props.internalSlug}
                    style={{
                      color: '#4B5B69',
                      textDecoration: 'underline',
                    }}>
                    {props.children}
                  </Link>
                );
              },
              li: ({ children }) => (
                <Grid item>
                  <ListItem>
                    <li className={classes.listStyle}>
                      {children}
                    </li>
                  </ListItem>
                </Grid>
              ),
						}}
					/>
				</Grid>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell
                  align='center'
                  style={{
                    color: '#4B5B69',
                    fontWeight: 600,
                    fontSize: '1.125rem',
                    fontFamily: 'Roboto',
                    border: '1px solid #E0E0E0',
                  }}>
                    {_rawCollectionTable[0].title}
                </TableCell>
                <TableCell
                  align='center'
                  style={{
                    color: '#4B5B69',
                    fontWeight: 600,
                    fontSize: '1.125rem',
                    fontFamily: 'Roboto',
                    borderRight: '1px solid #E0E0E0',
                    borderTop: '1px solid #E0E0E0',
                    borderBottom: '1px solid #E0E0E0',
                  }}>
                    {_rawCollectionTable[1].title}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Provision of Sites, Apps, products, and services:</u> providing our Sites, Apps, products, or services; providing promotional items upon request; and communicating with you in relation to those Sites, Apps, products, or services.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary in connection with any contract</b> that you have entered into with us, or to take steps prior to entering into a contract with us; or</li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of providing our Sites, Apps, products, or services (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms); or </li>
                        <li>We have obtained <b>your prior consent</b> to the Processing (this legal basis is only used in relation to Processing that is entirely voluntary – it is not used for Processing that is necessary or obligatory in any way).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Operating our business:</u> operating and managing our Sites, our Apps, our products, and our services; providing content to you; displaying advertising and other information to you; communicating and interacting with you via our Sites, our Apps, our products, or our services; and notifying you of changes to any of our Sites, our Apps, our products, or our services.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary in connection with any contract</b> that you have entered into with us, or to take steps prior to entering into a contract with us; or</li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of providing our Sites, our Apps, our products, or our services to you (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms); or </li>
                        <li>We have obtained <b>your prior consent</b> to the Processing (this legal basis is only used in relation to Processing that is entirely voluntary – it is not used for Processing that is necessary or obligatory in any way).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Communications and marketing:</u> communicating with you via any means (including via email, telephone, text message, social media, post or in person) to provide news items and other information in which you may be interested, subject always to obtaining your prior opt-in consent to the extent required under applicable law; personalizing our Sites, products and services for you; maintaining and updating your contact information where appropriate; obtaining your prior, opt-in consent where required; enabling and recording your choice to opt-out or unsubscribe, where applicable.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary in connection with any contract</b> that you have entered into with us, or to take steps prior to entering into a contract with us; or</li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of contacting you, subject always to compliance with applicable law (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms); or </li>
                        <li>We have obtained <b>your prior consent</b> to the Processing (this legal basis is only used in relation to Processing that is entirely voluntary – it is not used for Processing that is necessary or obligatory in any way).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Product safety communications:</u> communications in relation to product safety, including product recalls and product safety advisory notices
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary for compliance</b> with a legal obligation; or</li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of ensuring the safety, and proper use, of our products (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Management of IT systems:</u> management and operation of our communications, IT and security systems; and audits (including security audits) and monitoring of such systems.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary for compliance</b> with a legal obligation; or</li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of managing and maintaining our communications and IT systems (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Health and safety:</u> health and safety assessments and record keeping; providing a safe and secure environment at our premises; and compliance with related legal obligations
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary for compliance</b> with a legal obligation; or</li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of ensuring a safe environment at our premises (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms); or </li>
                        <li>The Processing is necessary to protect the vital interests of any individual.</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Financial management:</u> sales; finance; corporate audit; and vendor management.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of managing and operating the financial affairs of our business (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms); or </li>
                        <li>We have obtained <b>your prior consent</b> to the Processing (this legal basis is only used in relation to Processing that is entirely voluntary – it is not used for Processing that is necessary or obligatory in any way).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Surveys:</u> engaging with you for the purposes of obtaining your views on our Sites, our Apps, our products, or our services.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of conducting surveys, satisfaction reports and market research (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms); or </li>
                        <li>We have obtained <b>your prior consent</b> to the Processing (this legal basis is only used in relation to Processing that is entirely voluntary – it is not used for Processing that is necessary or obligatory in any way).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Security:</u> physical security of our premises (including records of visits to our premises); CCTV recordings; and electronic security (including login records and access details).
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary for compliance</b> with a legal obligation; or </li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of ensuring the physical and electronic security of our business and our premises (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Investigations:</u> detecting, investigating and preventing breaches of policy, and criminal offences, in accordance with applicable law
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary for compliance</b> with a legal obligation; or </li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of detecting, and protecting against, breaches of our policies and applicable laws (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Legal compliance:</u> compliance with our legal and regulatory obligations under applicable law.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary for compliance</b> with a legal obligation.</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Improving our Sites, Apps, products, and services:</u> identifying issues with our Sites, our Apps, our products, or our services; planning improvements to our Sites, our Apps, our products, or our services; and creating new Sites, Apps, products, or services.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of improving our Sites, our Apps, our products, or our services (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms); or </li>
                        <li>We have obtained <b>your prior consent</b> to the Processing (this legal basis is only used in relation to Processing that is entirely voluntary – it is not used for Processing that is necessary or obligatory in any way).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Fraud prevention:</u> Detecting, preventing and investigating fraud.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary for compliance</b> with a legal obligation (especially in respect of applicable employment law); or </li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of detecting, and protecting against, fraud (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms).</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Establishment, exercise and defense of legal claims:</u> management of legal claims; establishment of facts and claims, including collection, review and production of documents, facts, evidence and witness statements; exercise and defense of legal rights and claims, including formal legal proceedings.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary for compliance</b> with a legal obligation; </li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of establishing, exercising or defending our legal rights (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms); or </li>
                        <li>The Processing is necessary for the establishment, exercise or defense of legal claims.</li>
                      </ul>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      border: '1px solid #E0E0E0',
                      verticalAlign: 'top',
                      paddingTop: '2rem',
                    }}>
                      <u>Recruitment and job applications:</u> recruitment activities; advertising of positions; interview activities; analysis of suitability for the relevant position; records of hiring decisions; offer details; and acceptance details.
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: '1px solid #E0E0E0',
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      <ul>
                        <li>The Processing is <b>necessary for compliance</b> with a legal obligation (especially in respect of applicable employment law); or </li>
                        <li>We have a <b>legitimate interest</b> in carrying out the Processing for the purpose of recruitment activities and handling job applications (to the extent that such legitimate interest is not overridden by your interests, fundamental rights, or freedoms); or </li>
                        <li>We have obtained <b>your prior consent</b> to the Processing (this legal basis is only used in relation to Processing that is entirely voluntary – it is not used for Processing that is necessary or obligatory in any way).</li>
                      </ul>
                  </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

				<Grid container style={{ marginTop: '3rem'}}>
					<PortableText
						content={_rawMiddleContent}
						serializers={{
							// h2: ({ children }) => (
							// 	<Typography
							// 		color='primary'
							// 		variant='h2'
							// 		className={classes.subHeader}>
							// 		{children}
							// 	</Typography>
							// ),
							normal: ({ children }) => (
								<Grid item style={{ margin: '1rem 0' }}>
									<Typography variant='body1' className={classes.text}>
										{children}
									</Typography>
								</Grid>
							),
              strong: ({ children }) => (
                <span style={{ fontWeight: 600 }}>{children}</span>
              ),
							// h4: ({ children }) => (
							// 	<Typography
							// 		color='primary'
							// 		variant='h4'
							// 		className={classes.effectiveDate}>
							// 		{children}
							// 	</Typography>
							// ),
							emailPhoneLink: (props) => {
								return <a href={props.emailPhoneHref}>{props.children[0]}</a>;
							},
              link: (props) => {
                return (
                  <a
                    href={props.href}
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: '#4B5B69' }}>
                    {props.children[0]}
                  </a>
                );
              },
              internalLink: (props) => {
                return (
                  <Link
                    to={props.internalSlug}
                    style={{
                      color: '#4B5B69',
                      textDecoration: 'underline',
                    }}>
                    {props.children}
                  </Link>
                );
              },
              li: ({ children }) => (
                <Grid item>
                  <ListItem>
                    <li className={classes.listStyle}>
                      {children}
                    </li>
                  </ListItem>
                </Grid>
              ),
						}}
					/>
				</Grid>

        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: '#4B5B69',
                    fontWeight: 600,
                    fontSize: '1.125rem',
                    fontFamily: 'Roboto',
                    borderTop: '1px solid #e0e0e0',
                  }}>
                    {_rawDataRetentionTable[0].title}
                </TableCell>
                <TableCell
                  style={{
                    color: '#4B5B69',
                    fontWeight: 600,
                    fontSize: '1.125rem',
                    fontFamily: 'Roboto',
                    borderTop: '1px solid #e0e0e0',
                  }}>
                    {_rawDataRetentionTable[1].title}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      verticalAlign: 'top',
                    }}>
                      Performance of a contract to which you are (or your employer is) a party:
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      verticalAlign: 'top',
                    }}>
                      As long as is necessary for the performance of the contract.
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      verticalAlign: 'top',
                    }}>
                      Compliance with legal obligation:
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      As long as is necessary for us to comply with our legal obligations.
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      verticalAlign: 'top',
                    }}>
                      Our legitimate business interests:
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      Until you ask us to stop, unless an exception under applicable data protection law applies.
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                      verticalAlign: 'top',
                    }}>
                      Your consent:
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '1.125rem',
                      fontFamily: 'Roboto',
                    }}>
                      Until you revoke your consent, unless an exception under applicable data protection law applies.
                  </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

				<Grid container style={{ marginTop: '3rem'}}>
					<PortableText
						content={_rawEndingContent}
						serializers={{
							// h2: ({ children }) => (
							// 	<Typography
							// 		color='primary'
							// 		variant='h2'
							// 		className={classes.subHeader}>
							// 		{children}
							// 	</Typography>
							// ),
							normal: ({ children }) => (
								<Grid item style={{ margin: '1rem 0' }}>
									<Typography variant='body1' className={classes.text}>
										{children}
									</Typography>
								</Grid>
							),
              strong: ({ children }) => (
                <span style={{ fontWeight: 600 }}>{children}</span>
              ),
							// h4: ({ children }) => (
							// 	<Typography
							// 		color='primary'
							// 		variant='h4'
							// 		className={classes.effectiveDate}>
							// 		{children}
							// 	</Typography>
							// ),
							emailPhoneLink: (props) => {
								return <a href={props.emailPhoneHref}>{props.children[0]}</a>;
							},
							link: (props) => (
								<a href={props.href} target='_blank' rel='noreferrer'>
									{props.children[0]}
								</a>
							),
              li: ({ children }) => (
                <Grid item>
                  <ListItem>
                    <li className={classes.listStyle}>
                      {children}
                    </li>
                  </ListItem>
                </Grid>
              ),
						}}
					/>
				</Grid>

				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					/>
				) : null}
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query PrivacyPolicyPageQuery {
		privacy: allSanityPrivacyPolicy {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					logos {
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						logoLink
					}
          _rawBeginningContent
          _rawCollectionTable
          _rawMiddleContent
          _rawDataRetentionTable
          _rawEndingContent
				}
			}
		}
	}
`;

export default PrivacyPolicyPage;
